.videoPlate {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #000;

    &.isGuestQueue {
        width: 150px;
        height: 150px;
        margin: 0 10px;
        position: relative;
        border-radius: 6px;

        .buttonWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: none;
            justify-content: center;
            align-items: center;
            z-index: 111;

            .addIcon {
                width: 1.5em;
                height: 1.5em;
                color: #fff;
            }
        }

        &:hover {
            .buttonWrapper {
                display: flex;
            }
        }
    }

    &.activeStreamsVideoPlate {
        position: absolute;
        width: 200px;
        height: 180px;
        right: 0;
        top: 0;
        z-index: 150;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid #fff;
        background-color: #000;

        &.isHiddenVideo {
            width: 91px;
            height: 56px;
            & > video {
                visibility: hidden;
            }

            .toggleSelfVideoButton {
                visibility: visible;
                position: initial;
                margin: 0;
            }
        }
    }

    &.isActiveSpeaker {
        border: 4px solid yellow;
    }

    &:hover {
        .toggleSelfVideoButton {
            visibility: visible;
        }
    }

    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.username {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}

.toggleSelfVideoButton {
    visibility: hidden;
    transition: all 0.2s linear;
    position: absolute !important;
    cursor: pointer;
    z-index: 151;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -40px !important;
}

.visibilityIcon {
    width: 1.5em;
    height: 1.5em;
}
