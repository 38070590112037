.tabsContainer {
    margin-left: 50px;

    .selectedTab {
        color: #d6ddea !important;
    }

    .tabsIndicator {
        background-color: #d6ddea;
    }
}
