.avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    overflow: hidden;
    z-index: 11;

    &.minified {
        border: 0;
        border-radius: 0;
    }

    .image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        font-size: 5rem;
        font-weight: 400;

        &.imageSmall {
            width: 50%;
            height: 50%;
            font-size: 2.5rem;
        }
    }

    .hidden {
        display: none;
    }
}
