.videoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    background: #fff;
    height: 600px;
    width: 1000px;
    margin: 20px auto 0;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 10px;

    .buttonsContainer {
        cursor: pointer;
        background-color: var(--theme-primary-color);
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        display: flex;
        align-items: center;

        button {
            font-family: 'Montserrat', sans-serif;
            background-color: transparent;
            border: 0;
            outline: 0;
            font-weight: bold;
            font-size: 16px;
            color: var(--theme-white-color);
            text-transform: uppercase;
            cursor: pointer;

            div {
                display: flex;
                align-items: center;
            }

            svg {
                width: 22px;
                height: 22px;
                margin-right: 8px;

                path {
                    fill: var(--theme-white-color) !important;
                }
            }
        }
    }

    &.fullScreen {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 1001;
        left: 0;
        top: 0;
        margin: 0;

        .buttonsContainer {
            bottom: 0;
        }

        .selfVideo {
            z-index: 1111;
            height: 160px;
            width: 200px;
        }
    }
}
