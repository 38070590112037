.hostControlsContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .inner {
        background: #b4bac5;
        box-shadow: 0px 5px 1px rgb(0 0 0 / 7%), 0px 0px 7px rgb(0 0 0 / 12%), 0px 5px 12px rgb(0 0 0 / 20%);
        border-radius: 6px;
        width: 620px;

        .selectedButton {
            color: #262626;
        }
    }

    .recordingButton {
        position: absolute;
        bottom: -50px;
    }
}
