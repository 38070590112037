.container {
    margin: 20px 0 0 0;
    position: absolute !important;
    bottom: -80px;
    display: flex;
    align-items: center;

    .streamUrlInput {
        width: 400px;
        background: #fff;
        border-radius: 6px;
    }

    .addButton {
        height: 56px;
        margin: 8px 0 0 10px;
    }
}
