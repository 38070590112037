.container {
    padding: 20px;
    width: 1200px;
    height: calc(100% - 120px);
    max-width: 100%;
    margin: 0 auto;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tabsContainer {
            .tabIndicator {
                background: #262626;
            }

            .customTab {
                font-weight: 600;
                color: #262626;
            }
        }
    }

    .broadcastsListButton {
        &.deleteButton {
            margin-left: 15px;
            background: darken(red, 10%);
        }
    }
}
