.videoWrapper {
    width: 150px;
    height: 150px;
    margin: 0 10px;
    position: relative;
    border-radius: 6px;

    .video {
        width: inherit;
        height: inherit;
        object-fit: cover;
        border-radius: 6px;
        box-shadow: 0px -4px 6px rgb(0 0 0 / 7%), 0px 0px 7px rgb(0 0 0 / 12%), 0px 5px 12px rgb(0 0 0 / 20%);
    }

    .buttonWrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 111;

        .addIcon {
            width: 1.5em;
            height: 1.5em;
            color: #fff;
        }
    }

    &:hover {
        .buttonWrapper {
            display: flex;
        }
    }
}
