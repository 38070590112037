html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    margin: 10px;
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
