.container {
    margin-top: 20px;
    padding: 0 20px;

    .title {
        text-indent: 10px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 0;
    }

    .inner {
        display: flex;
        flex-direction: row;
        overflow: auto;
        padding: 10px 0;
    }
}
