.playbacksList {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: hidden;
}

.hidden {
    display: none;
}

.isFullScreen {
    height: calc(100% - 48px);
}
