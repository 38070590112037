.dialogContainer {
    width: 500px;

    .title {
        font-size: 22px;
    }

    .description {
        color: #262626;
    }

    .buttonWrapper {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}
