.videoWrapper {
    display: flex;
    max-height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border: 1px solid #000;
    box-sizing: border-box;
    background: #000;

    .videoPaused {
        visibility: hidden;
    }

    .isActiveSpeaker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid yellow;
        z-index: 11;
    }

    .enlarged {
        width: 100%;
        height: 100%;
    }

    .video {
        width: 100%;
        object-fit: cover;
    }

    .screenshare {
        object-fit: contain;
    }

    .videoEnlarged {
        object-fit: contain;
    }

    .videoOff {
        border: 1px solid #fff;
    }

    .videoOffPlate {
        position: absolute;
        display: flex;
        z-index: 100;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #000;
    }

    .audioStream {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }

    .userInfo {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        & svg {
            margin-right: 5px;
            color: #fff;
        }
    }

    .userName {
        color: #fff;
        text-transform: capitalize;
        margin-left: 10px;
        font-size: 18px;
        font-weight: 500;
    }
}
