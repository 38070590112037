.menuWrapper {
    position: absolute;
    left: 1%;
    top: 1%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    z-index: 12;

    svg {
        color: #262626 !important;
    }
}
