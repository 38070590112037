.header {
    color: #000;
    background-color: #262626;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .inner {
        display: flex;
        align-items: center;
    }

    .headerTitle {
        width: 180px;
        color: #fff;
        cursor: pointer;
    }
}
